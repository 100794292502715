// src/components/MainLayout.tsx
import React, { useState, useEffect } from 'react';
import { Layout, Menu, Skeleton } from 'antd';
import { UserOutlined, HomeOutlined, FileOutlined, SettingOutlined, TeamOutlined, LogoutOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import './MainLayout.css';
import logo from '../../public/images/logo.svg';
import { decodeToken } from 'react-jwt';
import { gql, useQuery } from '@apollo/client';

const { Sider, Content } = Layout;

export const GET_USER_FIRST_LAST_NAME = gql`
  query GetUserFirstLastName($userId: Float!) {
    user(id: $userId) {
      firstName
      lastName
    }
  }
`;

interface GetUserFirstLastNameData {
  user: {
    firstName: string;
    lastName: string;
  };
}

interface GetUserFirstLastNameVars {
  userId: number;
}

const MainLayout: React.FC = () => {

  const { loading, data } = useQuery<GetUserFirstLastNameData, GetUserFirstLastNameVars>(
    GET_USER_FIRST_LAST_NAME,
    {
      variables: { userId: Number(localStorage.getItem('userId')) },
    }
  );

  const userName = `${data?.user?.firstName} ${data?.user?.lastName}`; // Replace with actual user name
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);

  const token = localStorage.getItem('accessToken');
  const result: any = decodeToken(token as string);
  const role = result?.role;

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'auth/login') {
      localStorage.clear();
    }
    navigate(`/${e.key}`);
  };

  const menuItems: MenuProps['items'] = [
    {
      key: 'bills',
      icon: <HomeOutlined />,
      label: 'Bills'
    },
    {
      key: 'accounts',
      icon: <UserOutlined />,
      label: 'Accounts'
    },
    {
      key: 'documents',
      icon: <FileOutlined />,
      label: 'Documents'
    },
    {
      key: 'settings',
      icon: <SettingOutlined />,
      label: 'Settings'
    },
    {
      key: 'users',
      icon: <TeamOutlined />,
      label: 'Users',
      style: { display: ['super_admin', 'admin'].includes(role) ? 'block' : 'none' }
    },
    {
      key: 'auth/login',
      icon: <LogoutOutlined />,
      label: 'Logout',
      style: {
        position: 'absolute',
        bottom: 50,
        zIndex: 1,
        transition: 'all 0.2s',
      }
    }
  ];

  // Set the default selected key based on the current path
  const [selectedKeys, setSelectedKeys] = useState<string[]>([location.pathname.slice(1)]);

  // Update the selected key when the location changes
  useEffect(() => {
    setSelectedKeys([location.pathname.slice(1)]);
  }, [location.pathname]);

  return (
    <Layout style={{ minHeight: '100vh', maxHeight: '100vh', overflow: "hidden" }}>
      <Sider theme="light" width={200} collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <div className="logo">
          <img src={logo} alt="Logo" className="logo-img" />
        </div>
        {collapsed ? null : <div className="user-name">
          {loading ? <><Skeleton.Button active={false} size="small" /> <Skeleton.Button active={false} size="small" /></> : userName}
        </div>}
        <Menu
          mode="inline"
          selectedKeys={selectedKeys}
          onClick={handleMenuClick}
          style={{ height: 'calc(100% - 48px)', borderRight: 0 }}
          items={menuItems}
        />
      </Sider>
      <Layout>
        <Content
          style={{
            padding: '0 24px 0 24px',
            minHeight: '100%',
            overflow: 'auto'
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
