import React, { useState } from 'react';
import { Form, Input, Button, Checkbox } from 'antd';
import { useLazyQuery, gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import logo from '../../public/images/logo.svg';


const LOGIN_QUERY = gql`
  query Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      accessToken
    }
  }
`;

const Login: React.FC = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate();
  const [login, { data, loading, error }] = useLazyQuery(LOGIN_QUERY, {
    onCompleted: (data) => {
      if (data && data.login && data.login.accessToken) {
        const { accessToken } = data.login;
        localStorage.setItem('accessToken', accessToken);
        navigate('/auth/mfa');
      }
    },
  });

  const onButtonClick = async () => {
    login({
      variables: {
        username: email,
        password: password
      }
    });
  }

  return (
    <div className="login-container">
      <Form
        name="login"
        initialValues={{ remember: true }}
        className="login-form"
        action="#"
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: 'Email is required.' }]}
        >
          <Input autoComplete="username" name="username" placeholder="Email" onChange={(ev) => setEmail(ev.target.value)} />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Password is required.' }]}
        >
          <Input.Password autoComplete="current-password" name="password" onChange={(ev) => setPassword(ev.target.value)} placeholder="Password" />
        </Form.Item>

        <div className="login-options">
          <a className="login-form-forgot" href="#">
            Forgot Password
          </a>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Remember Me</Checkbox>
          </Form.Item>
        </div>

        <Button type="primary" className="login-form-button" style={{ marginBottom: 0 }} onClick={async () => { await onButtonClick() }}>
          Log In
        </Button>

      </Form>
      <div className="login-footer">
        <p>
          By continuing you agree to PAYTRUST <a href="#">Terms of Service</a> and <a href="#">Privacy Policy</a>
        </p>
        <p>support@silverbills.com | 1-800-836-7562</p>
        <p>Version 1.5.2.202405101</p>
      </div>
    </div>
  );
};

export default Login;
