import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route, Navigate, useParams, } from "react-router-dom";
import { ApolloProvider } from '@apollo/client';
import client from './apolloClient';
import Login from "./pages/Login/Login";
import AuthLayout from './components/layouts/AuthLayout';
import MainLayout from './components/layouts/MainLayout';
import Mfa from './pages/Mfa/Mfa';
import Accounts from './pages/Accounts/Accounts';
import { ConfigProvider } from 'antd'
import Bills from './pages/Bills/Bills';
import Documents from './pages/Documents/Documents';
import Settings from './pages/Settings/Settings';
import Users from './pages/Users/Users';
import { isExpired } from 'react-jwt';
import Account from './pages/Accounts/Account'
import BillUpload from './pages/BillUpload/BillUpload';

const PrivateRoutes = () => {
  const token = localStorage.getItem('accessToken');
  let expired = true;
  if (token && !isExpired(token as string)) expired = false;
  return (
    expired ? <Navigate to='/auth/login' /> : <MainLayout />
  )
}

const AccountWrapper: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  return <Account accountId={Number(id!)} />;
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            headerBg: '#F6F4F7',
            lightSiderBg: '#F6F4F7',
          },
          Menu: {
            itemSelectedBg: '#451f49',
            itemSelectedColor: '#FFFFFF',
            itemBg: '#F6F4F7'
          }

        },
        token: {
          colorPrimary: '#451f49',
          colorBgContainer: '#F6F4F7',
          colorLink: '#592C5E'
        },
      }}
    >
      <ApolloProvider client={client}>
        <Routes>
          <Route path="/auth" element={<AuthLayout />}>
            <Route index element={<Login />} />
            <Route path="login" element={<Login />} />
            <Route path="mfa" element={<Mfa />} />
            <Route
              path="*"
              element={<Navigate to="/" replace={true} />}
            />
          </Route>
          <Route path="/" element={<PrivateRoutes />}>
            <Route index element={<Login />} />
            <Route path="bills" element={<Bills />} />
            <Route path="accounts/:id" element={<AccountWrapper />} />
            <Route path="accounts" element={<Accounts />} />
            <Route path="documents" element={<Documents />} />
            <Route path="settings" element={<Settings />} />
            <Route path="users" element={<Users />} />
            <Route path="bill-upload" element={<BillUpload />} />
          </Route>
        </Routes>
      </ApolloProvider>
    </ConfigProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
