import { Outlet } from "react-router-dom";
import './AuthLayout.css';
import { Layout, Menu } from 'antd';
import logo from '../../public/images/logo.svg';
const { Header } = Layout;

const items = [
  {
    key: 1,
    label: 'Benefits'
  },
  {
    key: 2,
    label: 'How To'
  },
  {
    key: 3,
    label: 'FAQs'
  },
  {
    key: 4,
    label: 'Support'
  },
  {
    key: 5,
    label: 'Dementia Caregivers'
  }
];

const AuthLayout = () => {
  return (
    <Layout>
      <div className='wrapper'>
        <Header
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: 0
          }}
        >
          <div className="logo">
            <img src={logo} alt="Logo" />
          </div>
          <Menu
            theme="light"
            mode="horizontal"
            defaultSelectedKeys={['2']}
            items={items}
          />
        </Header>
        <div className='content-wrapper'>
          <Outlet />
        </div>
      </div>
    </Layout>

  )
};

export default AuthLayout;